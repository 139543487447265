import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/ms/oauth2/model/User';
import { AuthenticationService } from 'src/app/ms/oauth2/service/authentication.service';
import { UserService } from 'src/app/ms/oauth2/service/user.service';

@Component({
  selector: 'app-confirm-code-reset',
  templateUrl: './confirm-code-reset.component.html',
  styleUrls: ['./confirm-code-reset.component.scss']
})
export class ConfirmCodeResetComponent implements OnInit {

  isReset: boolean;
  accessToken2 : any = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJFYXN5bXMiXSwiZXhwIjoxNjk2ODY1NTE1LCJhdXRob3JpdGllcyI6WyJST0xFX1BMQVRGT1JNX1VJIl0sImp0aSI6ImJiZjQwNjdjLTk4MTktNDhmYS1hZGNiLTliNjYyMmMzYjZhNiIsImNsaWVudF9pZCI6InBsYXRmb3JtLXVpIn0.fn56ToQ8kACfh2eitz9ZpOENWMFzz5835SyjXGSdJ_5N4ajNoJ0Rh0e-5iwWP2UQxYFZ6enU5IeZXk_YqA6J_n6tt59lQAAXwbw-N0ohrnovXA7bQxedO6iQP7kcvGu75NZ5K_jZw6FchPrRc26MS3L9GycMupn4OAc9sW-GGydEoiBMo4vYnFOj2bZp4usai-2z8_FSUYP1wvOJB4--NqZfTSEnT6CTxogPZ0dInL_auwv-E1SHjmK_5hIIIfLrumVEMYzKoZ87L6LpQdqcNWQn0fPIJy6qBoDKwtbJrO32gd6FF2VopyB_gl8pQuEQ_-0SngDTzoqEpJcy9MGPLQ"; 


  constructor(
    private el: ElementRef,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private _formBuilderConfirmCode: FormBuilder
     ) { }


  ngOnInit(): void {

  }
  
    
      
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Admin } from '../model/Admin';


@Injectable({
  providedIn: 'root',
})
export class AdminService {
  baseUrl: string = '/healthcare-facility-ms/api/v1/admins';

  constructor(private http: HttpClient) { }

    addNewAdmin(admin:Admin){

      return this.http.post(this.baseUrl, admin)
    }

    getAdmin(){
      return this.http.get(this.baseUrl)
    }
}

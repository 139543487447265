import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmCodeResetComponent } from './confirm-code-reset.component';


const routes: Routes = [
  {
    path: '',
    component: ConfirmCodeResetComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfirmCodeResetRoutingModule { }

<div class="auth-wrapper align-items-stretch aut-bg-img">
    <div class="flex-grow-1">
      <div class="h-100 d-md-flex align-items-center auth-side-img">
        <div class="col-sm-10 auth-content w-auto">
          <h1 class="text-white my-4">Mot de passe changé avec succès !</h1>
        </div>
      </div>
      <div class="auth-side-form">
        <div class=" auth-content">
            <div class="text-center">
                <img src="http://cdn.mcauto-images-production.sendgrid.net/21d2b42fed1d56c5/e8fd6de1-3dbc-4843-92e6-8b23e295ed5a/1835x1835.jpg" alt="Logo Nearcare" width="50%">
            </div>
  
          <div class="input-group mb-4" > 
            <h4 class="mb-4 f-w-400">Accèdez à l'application mobile :</h4>
          </div>
          <div style="text-align: center;">
            <a href="http://nearcareapp.page.link/?link=https://nearcare-app.com/&apn=com.nearcare"><img src="assets/images/reset-password/google-play-badge.png" alt="badge google play" style="width: 7rem;"></a>
            <a href="https://apps.apple.com/fr/app/nearcare/id1637618846"><img src="assets/images/reset-password/apple-store-badge.svg" alt="badge apple store"style="width: 5.7rem; height=2rem;" ></a>         
          </div>
          <div class="text-center">
            <div class="saprator my-4"><span>OU</span></div>
            <p class="mb-0 text-muted">Revenir à la page précédente <a [routerLink]="['/change-password-v2']" class="f-w-400">Retour</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
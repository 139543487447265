import { Injectable } from "@angular/core";

import { Token } from "../model/Token";

const TOKEN_KEY = "auth-token";
const USER_KEY = "auth-user";
const USER_DATA_KEY = "user-data";
const LANGUAGE_KEY = "language";

@Injectable({
  providedIn: "root",
})
export class UserStorageService {
  constructor() {}

  signOut() {
    localStorage.clear();
  }

  public saveLanguage(language: string) {
    localStorage.removeItem(LANGUAGE_KEY);
    localStorage.setItem(LANGUAGE_KEY, language);
  }
  public saveToken(token: Token) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  public saveUser(user) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  public saveUserDate(userData) {
    localStorage.removeItem(USER_DATA_KEY);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
  }

  public getUserData() {
    return JSON.parse(localStorage.getItem(USER_DATA_KEY));
  }

  public getToken() {
    return JSON.parse(localStorage.getItem(TOKEN_KEY));
  }
}

<div style="display: block;">
  <canvas
    *ngIf="chartReady"
    mdbChart
    [chartType]="chartType"
    [datasets]="chartDatasets"
    [labels]="chartLabels"
    [colors]="chartColors"
    [options]="chartOptions"
    [legend]="true"
    (chartHover)="chartHovered($event)"
    (chartClick)="chartClicked($event)"
  >
  </canvas>
</div>
